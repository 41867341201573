<template>
<div id="user-profile">
    <b-row>
        <b-col lg="12" cols="12" order="1" order-lg="1">
            <b-card class="profile-header mb-2" body-class="p-0">
                <b-card-body>
                    <b-row class="mb-2">
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>Sınıf/Ders</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                        </b-col>

                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>Alt Sınıf</label>
                            <v-select  id="altsinif" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="childClassId" :options="childClass" class="w-100" :reduce="val => val.class" :getOptionLabel="option => option.class.name" />
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <b-button block variant="success" @click="getStudent()">FİLTRELE</b-button>
                        </b-col>
                    </b-row>
                    <br>
                   <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <b-button block variant="primary" @click="getResultxlsx()">İNDİR</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>

              <b-card class="profile-header mb-2" no-body>
                    <b-table id="sinif" :items="users" responsive :fields="questionFields" class="mb-0">
                         <template #cell(fullName)="data">
                            <div class="position-relative">
                                <b-row>
                                    <b-col lg="2" cols="12" order="1" order-lg="1">
                                        <div class="profile-img">
                                            <b-img :src="data.item.profileImage"   style="width:126px;height:126px;object-fit: contain" rounded fluid alt="profile photo" />
                                        </div>
                                    </b-col>
                                    <b-col lg="10" cols="12" order="1" order-lg="1">

                                        <div class="profile-title">
                                            <h2 class="text-black">
                                                {{data.item.firstName}} {{data.item.lastName}}
                                            </h2>
                                            
                                        </div>

                                    

                                    </b-col>
                                </b-row>
                            </div>
                         </template>
                     
                    </b-table>
            </b-card>

           
        </b-col>
        <b-col lg="7" cols="12" order="2" order-lg="2">
            
            <section id="profile-info">
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu==0">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <app-timeline>
                                    <app-timeline-item variant="success" v-for="video in videos" :key="video.video.id">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{video.video.name}}</h6>
                                            <small class="text-muted">{{video.firstDate}}</small>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <div style="width:100%" class="mb-1 mb-sm-0">
                                                <b-progress key="success" class="progress-bar-success">
                                                    <b-progress-bar animated :value="video.percent" variant="success">
                                                        <span>İzleme Süresi: <strong>05:00</strong></span>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu==1">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <app-timeline>
                                    <app-timeline-item :variant="getTestVariant(test.status)" v-for="test in tests" :key="test.testId">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{test.title}}</h6>
                                            <small class="text-muted">{{test.startDate}}</small>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0  text-center">
                                                <span class="text-muted mb-50">D Y B</span>
                                                <div>
                                                    <b-avatar :text="test.correct.toString()" class="mr-50" size="24" variant="light-success" />
                                                    <b-avatar :text="test.wrong.toString()" class="mr-50" size="24" variant="light-danger" />
                                                    <b-avatar :text="test.empty.toString()" class="mr-50" size="24" variant="light-info" />
                                                </div>
                                            </div>
                                            <!-- 2nd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50 d-block">Puan</span>
                                                <span>{{parseFloat(test.point).toFixed(2)}}</span>
                                            </div>
                                            <!-- 3rd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50 d-block ">Bitiş Tarihi</span>
                                                <span>{{test.endDate}}</span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>

                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu==2">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <app-timeline>
                                    <app-timeline-item :variant="lack.isDelete==true?'success':'danger'" v-for="lack in lacks" :key="lack.gainId">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{lack.title}}</h6>
                                            <small class="text-muted">{{lack.createdAt}}</small>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0  text-center">
                                                <span class="text-muted mb-50">
                                                    {{lack.lesson+" > "+lack.childLesson+" > "+lack.topic+" > "+lack.videoGroup}}
                                                </span>
                                            </div>
                                            <div class="mb-1 mb-sm-0  text-center">
                                                <span class="text-muted mb-50">
                                                    {{"Eksik Giderme Tarihi : "+lack.deletedAt}}
                                                </span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </section>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-col>
    </b-row>
</div>
</template>

<script>

import { BOverlay, BProgress, BProgressBar, BRow, BCol, BCard, BCardBody, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BTable, BAvatar } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import XLSX from 'xlsx';
import { jsPDF } from "jspdf";
import Vue from "vue"
import VueHtml2Canvas from 'vue-html2canvas';
 
Vue.use(VueHtml2Canvas);
Vue.use(require('vue-moment'));
  import moment from 'moment'
export default {
    components: {
        BOverlay,
        BProgressBar,
        BProgress,
        BTable,
        BAvatar,
        vSelect,
        AppTimeline,
        AppTimelineItem,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BImg,
        BNavbar,
        BNavbarToggle,
        BCollapse,
        BTabs,
        BNavItem,
        BButton,
    },
    watch: {
        selectedLesson: function (val) {
            this.childClass = [];
            this.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
    },
    data() {
        return {
            loading: false,
            selectedMenu: 0,
            selectedLesson: '',
            childClassId: '',
            users: [],
            lessons: [],
            childClass: [],
            questionFields: [
                { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
               // { key: 'action', label: 'İşlem' },
            ],
            selectedUser: {},
            videos: [],
            tests: [],
            lacks: []
        }
    },
    mounted(){

 
 
    },
    created() {
        this.getLesson();


       


    },
    mounted(){

    },
    methods: {
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },
        async getStudent() {
            var users = await this.$http.get("Teacher/StudentsChildClass/" + this.childClassId.id);
            this.users = users.data.data;
        },
        async getReport(user) {
            this.selectedUser = user;
            this.loading = true;
            await this.getVideo();
            await this.getTest();
            await this.getLacks();
            this.loading = false;
        },
        async getVideo() {
            var videos = await this.$http.get("Report/VideoWatching/" + this.selectedUser.id);
            this.videos = videos.data.data;
        },
        async getTest() {
            var tests = await this.$http.get("Report/Test/" + this.selectedUser.id);
            this.tests = tests.data.data;
        },
        async getLacks() {
            var lacks = await this.$http.get("Report/Lacks/" + this.selectedUser.id);
            this.lacks = lacks.data.data;
        },
    
        async getResultxlsx() {
            /*
                resimli xls calisan
            var a = document.createElement('a');
            //getting data from our div that contains the HTML table
            var data_type = 'data:application/vnd.ms-excel';
            var table_div = '<title>Demo for huge data</title> <table> <thead> <tr> <th colspan="5"> Demoe By <a href="http://codePattern.net/blog">CodePattern.net</a> </th> </tr> </thead> <tbody> <tr> <td>Anil Kumar</td> <td>2012</td> <td>Delhi</td> <td>India</td> <td><img src="https://www.gravatar.com/avatar/821e839b1cf8d336c133f9b331cea821?s=80" alt=""   /></td> </tr> <tr> <td>abc</td> <td>12</td> <td>Delhi</td> <td>India</td> <td>Earth</td> </tr> <tr> <td>abc</td> <td>12</td> <td>Delhi</td> <td>India</td> <td>Earth</td> </tr> <tr> <td>abc</td> <td>12</td> <td>Delhi</td> <td>India</td> <td>Earth</td> </tr> <tr> <td>abc</td> <td>12</td> <td>Delhi</td> <td>India</td> <td>Earth</td> </tr> <tr> <td>abc</td> <td>12</td> <td>Delhi</td> <td>India</td> <td>Earth</td> </tr> </tbody> </table>';
              window.open('data:application/vnd.ms-excel,' + encodeURIComponent( table_div));
            //just in case, prevent default behaviour
            e.preventDefault();
           */
             /*
            var filename="";
            var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
            var postHtml = "</body></html>";
            var body="";

            console.log(this.users);
            
            var html = preHtml+body.innerHTML+postHtml;

            var blob = new Blob(['\ufeff', html], {
                type: 'application/msword'
            });
            
            // Specify link url
            var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
            
            // Specify file name
            filename = filename?filename+'.doc':'document.doc';
            
            // Create download link element
            var downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);
            
            if(navigator.msSaveOrOpenBlob ){
                navigator.msSaveOrOpenBlob(blob, filename);
            }else{
                // Create a link to the file
                downloadLink.href = url;
                
                // Setting the file name
                downloadLink.download = filename;
                
                //triggering the function
              // downloadLink.click();
            }
           //console.log(this.users);
         */
           let excelItems = [];
            var index = 1;


            var data_type = 'data:application/vnd.ms-excel';
            var table_header='<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><meta charset="UTF-8">';
            var table_div_start = '<title>Sınıf Listesi</title> <table> <tbody> ' +
            '<thead> <tr> <th colspan="5" width="600"> Sınıf Listesi </th> </tr> <tr> <th > Sıra </th><th>Foto</th><th>İsim</th><th>Soyisim</th><th>Sınıf</th> </tr></thead>';
            var table_div_body="";
             var table_div_end = ' </tbody> </table></html>';
            //just in case, prevent default behaviour
         
           

            this.users.forEach(x => {
                //console.log(x);
                
                table_div_body =  table_div_body +  ' <tr height="60"> <td>' +  index  +'</td> <td><img width="60" height="60" src="'  +  x.profileImage + '" alt=""   /></td> </td> ' + 
                '<td>'  + x.firstName + '</td>' +
                '<td>'  + x.lastName + '</td>' +
                '<td>'  + this.childClassId.name + '</td>' +

                
                
                '</tr>' ;

                excelItems.push({
                    "Sıra": index,
                    "Foto":x.profileImage,// x.answers.filter((x) => x.isTrue == true)[0].text,
                    "İsim ":x.firstName + " " + x.lastName
                });
                index++;
            })
            window.open('data:application/vnd.ms-excel,' + encodeURIComponent( table_header + table_div_start + table_div_body + table_div_end));
           
            /*let data = XLSX.utils.json_to_sheet(excelItems);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
          
            await XLSX.writeFile(wb,"Sınıf Listesi" + ".xlsx");*/
        },
        getTestVariant(status) {
            switch (status) {
                case "HasResulted":
                    return "success";
                case "Waiting":
                    return "info";
                case "Processing":
                    return "warning";
            }
        }
    }
}


</script>



 

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
 